<template>
  <div class="container text-base px-4 lg:px-8">
    <div>
      <h1
        class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl pt-10 pb-10"
      >
        <span class="block xl:inline">Curriculum Vitae</span>
      </h1>
      <div>
        <section class="mb-6" v-if="$i18n.locale === 'en'">
          <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
            Born
          </div>
          <div class="lg:inline-block align-top lg:w-8/12 w-full">
            27.12.1959 in Vienna
          </div>
        </section>
        <section class="mb-6" v-if="$i18n.locale === 'de'">
          <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
            Geboren
          </div>
          <div class="lg:inline-block align-top lg:w-8/12 w-full">
            27.12.1959 in Wien
          </div>
        </section>
        <section class="mb-6" v-if="$i18n.locale === 'de'">
          <div class="lg:inline-block lg:w-3/12 bullet font-bold">Familie</div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            verheiratet, 3 Kinder
          </div>
        </section>
        <section class="mb-6" v-if="$i18n.locale === 'en'">
          <div class="lg:inline-block lg:w-3/12 bullet font-bold">Family</div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            married, 3 children
          </div>
        </section>
        <section class="mb-6" v-if="$i18n.locale === 'de'">
          <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
            Ausbildung
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Professional Management Degree – Harvard Business School, Boston<br />
            Betriebs- und volkswirtschaftliche Studien – WU & Uni Wien<br />
            Doktorat der Rechtswissenschaften – Uni Wien<br />
            Abiturientenlehrgang – Handelsakademie Wien<br />
            Matura – Bundesgymnasium Wien
          </div>
        </section>
        <section class="mb-6" v-if="$i18n.locale === 'en'">
          <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
            Education
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Professional Management Degree – Harvard Business School, Boston<br />
            Econ & Business Administration studies - WU & Uni Vienna<br />
            Doktorate of Law – Uni Vienna<br />
            Graduate course - Trade Academy, Vienna<br />
            High school diploma, Vienna
          </div>
        </section>
      </div>
    </div>
    <div id="projects" class="prose" v-if="$i18n.locale === 'de'">
      <h2 class="section-headline bullet pb-4 font-bold">Berufserfahrung</h2>
      <div class="mb-8">
        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            Seit 06/2024
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Vorstand der Management Trust Holding AG, Wien
          </div>
        </section>
        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            01/2022-12/2024
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Corporate Relations & Umdasch Group Foundation
          </div>
        </section>
        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            07/2021-12/2021
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Vorstand der Umdasch Group AG, Amstetten
          </div>
        </section>
        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            2016 – 06/2021
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Sprecher des Vorstands der Umdasch Group AG, Amstetten
          </div>
        </section>
        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            2010 – 2015
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Vorstandsvorsitzender der Umdasch AG, Amstetten
          </div>
        </section>
        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            2003 – 2010
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Vorstandsvorsitzender der Zumtobel Group AG, Dornbirn
          </div>
        </section>

        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            2001 – 2003
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Managing Director UBS Warburg, London
          </div>
        </section>

        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            1994 – 2001
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            CEO Tyrolit Gruppe (Swarovski Gruppe), Schwaz, Tirol
          </div>
        </section>

        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            1991 - 1993
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            CFO und COO, Zale Corporation (Swarovski Gruppe), Dallas, Texas
          </div>
        </section>

        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            1987 - 1990
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Vice President Finance, Daniel Swarovski Corp., Feldmeilen, Schweiz
          </div>
        </section>

        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            1986
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Assistent des Finanzvorstandes, Swarovski America Ltd, Rhode Island
          </div>
        </section>

        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            1984 - 1985
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Revisionsassistent, Wiener Treuhand- und WirtschaftsberatungsgmbH,
            Wien
          </div>
        </section>
      </div>
      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          Aufsichtsrats<br />mitglied
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          Doka GmbH, Amstetten<br />
          Fronius International GmbH, Wels<br />
          Greiner Holding AG, Kremsmünster<br />
          Plansee Holding AG, Reutte<br />
          Schwechater Kabelwerke Gesellschaft m.b.H., Schwechat<br />
          Umdasch Store Makers GmbH, Amstetten
        </div>
      </section>

      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          Vorsitzender des Aufsichtsrates
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          BGO Holding GmbH, Wien<br />
          Ebner Industrieofenbau Gesellschaft m.b.H., Leonding<br />
          EVVA Sicherheitstechnologie GmbH, Wien<br />
          Rail Holding AG (WESTbahn), Wien
        </div>
      </section>

      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          Stiftungsvorstand
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          Czipin Privatstiftung, Wien<br />
          Flatlake Privatstiftung, Wien<br />
          Josef und Waltraud Welser Privatstiftung, Ybbsitz<br />
          Peter Ebner Privatstiftung, Leonding<br />
          SKW Privatstiftung, Wien
        </div>
      </section>

      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          Geschäftsführender Gesellschafter
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          AJL Corperate Advisors GesmbH, Wien<br />
          Cool‘n motion GesmbH, Wien<br />
        </div>
      </section>

      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          Gesellschafter
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          Hotel Beethoven GmbH & Co KG, Wien
        </div>
      </section>

      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          Partner
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          Point Ann Advisory Pty. Ltd., Perth Australien
        </div>
      </section>

      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          NGO-Mandate
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          Mitglied des Vorstands der Industriellenvereinigung,
          Niederösterreich<br />Stv. Obmann Fachverband der Holzindustrie,
          Wien<br />
          Vorstandsvorsitzender der Agenda Austria - Denkfabrik, Wien
        </div>
      </section>
    </div>
    <div id="projects" class="prose" v-if="$i18n.locale === 'en'">
      <h2 class="section-headline bullet pb-4 font-bold">
        Professional Experience
      </h2>
      <div class="mb-8">
        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            Since 06/2024
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Member Executive Board Management Trust Holding AG, Vienna
          </div>
        </section>
        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            01/2022-12/2024
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Corporate Relations & Umdasch Group Foundation
          </div>
        </section>
        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            07/2021-12/2021
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Member Executive Board Umdasch Group AG, Amstetten
          </div>
        </section>
        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            2016 – 06/2021
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Speaker Executive Board Umdasch Group AG, Amstetten
          </div>
        </section>
        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            2010 – 2015
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Chairman Executive Board Umdasch AG, Amstetten
          </div>
        </section>
        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            2003 – 2010
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Chairman Executive Board Zumtobel Group AG, Dornbirn
          </div>
        </section>

        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            2001 – 2003
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Managing Director UBS Warburg, London
          </div>
        </section>

        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            1994 – 2001
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            CEO Tyrolit Group (Swarovski Group), Schwaz, Tirol
          </div>
        </section>

        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            1991 - 1993
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            CFO und COO, Zale Corporation (Swarovski Group), Dallas, Texas
          </div>
        </section>

        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            1987 - 1990
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Vice President Finance, Daniel Swarovski Corp., Feldmeilen, Schweiz
          </div>
        </section>

        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            1986
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            Assistent to the CFO, Swarovski America Ltd, Rhode Island
          </div>
        </section>

        <section class="mb-0">
          <div
            class="lg:inline-block lg:w-3/12 lg:align-top mb-2 bullet font-bold"
          >
            1984 - 1985
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            CPA Trainee, Wiener Treuhand- und WirtschaftsberatungsgmbH, Vienna
          </div>
        </section>
      </div>
      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          Board member
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          Doka GmbH, Amstetten<br />
          Fronius International GmbH, Wels<br />
          Greiner Holding AG, Kremsmünster<br />
          Plansee Holding AG, Reutte<br />
          Schwechater Kabelwerke Gesellschaft m.b.H., Schwechat<br />
          Umdasch Store Makers GmbH, Amstetten
        </div>
      </section>

      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          Board chairman
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          BGO Holding GmbH, Vienna<br />
          Ebner Industrieofenbau Gesellschaft m.b.H., Leonding<br />
          EVVA Sicherheitstechnologie GmbH, Vienna<br />
          Rail Holding AG (WESTbahn), Vienna
        </div>
      </section>

      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          Trustee
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          Czipin Privatstiftung, Vienna<br />
          Flatlake Privatstiftung, Vienna<br />
          Josef und Waltraud Welser Privatstiftung, Ybbsitz<br />
          Peter Ebner Privatstiftung, Leonding<br />
          SKW Privatstiftung, Vienna
        </div>
      </section>

      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          Managing shareholder
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          AJL Corperate Advisors GesmbH, Vienna<br />
          Cool‘n motion GesmbH, Vienna<br />
        </div>
      </section>

      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          Shareholder
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          Hotel Beethoven GmbH & Co KG, Vienna
        </div>
      </section>

      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          Partner
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          Point Ann Advisory Pty. Ltd., Perth Australia
        </div>
      </section>

      <section class="mb-6">
        <div class="lg:inline-block align-top lg:w-3/12 bullet font-bold">
          NGO-Mandat
        </div>
        <div class="lg:inline-block lg:w-8/12 w-full">
          Chairman Agenda Austria - Think Tank, Vienna<br />
          Member of the Board Federation of Austrian Industry, Lower Austria<br />Vice
          Chairman Association of the Wood Industry, Vienna
        </div>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  name: "CurriculumVita",
};
</script>
