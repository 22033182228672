export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach über 30 Jahren in exekutiver Management Verantwortung habe ich mich zu Beginn des Jahres 2022 entschlossen, meine internationale Führungserfahrung in Zukunft als unabhängiger Berater, Coach, Aufsichtsrat oder Stiftungsvorstand zu teilen.<br /><br />Den Großteil meiner beruflichen Laufbahn habe ich in internationalen österreichischen Familien-Industrie-Konzernen verbracht, die auch als „Hidden Champions“ beschrieben wurden. Ich habe zwei Mal Unternehmen erfolgreich an die Börse gebracht, einmal an die NASDAQ in NY und einmal an die Wiener Börse, in beiden Fällen nach intensiven operativen Turnarounds. Neben meiner langjährigen Industrieerfahrung durfte ich 3 Jahre lang auch als Managing Director der UBS Investmentbank in London internationale Finanztransaktionen von Industriekunden begleiten.<br /><br /> Als wichtigstes verbindendes Erfolgsrezept ist es mir ein Anliegen, meinen stark werteorientierten Führungsansatz herauszustreichen. Persönliche Integrität, Verlässlichkeit und Einsatzfreude bleiben die Basis meines sehr teamorientierten Erfolgs. Authentizität ist meine größte Stärke beim Führen multikultureller internationaler Teams."])},
  "abjaenner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ab Jänner 2022"])},
  "geboren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboren"])},
  "familie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familie"])},
  "ausbildung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausbildung"])},
  "berufserfahrung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufserfahrung"])},
  "aufsichtsratmitglied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufsichtsratmitglied"])},
  "vorsitzenderdesaufsichtsrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voristzender des Aufsichtsrates"])},
  "stiftungsvorstand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiftungsvorstand"])},
  "geschaeftsfuehrender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführender Gesellschafter"])},
  "ngo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NGO-Mandate"])}
}