<template>
  <NavBar />
  <HomeHero />
  <CurriculumVita />
  <ContactPage ref="#contact" />
  <FooterBar />
</template>

<script>
import HomeHero from "./components/HomeHero.vue";
import CurriculumVita from "./components/CurriculumVita.vue";
import NavBar from "./components/Nav.vue";
import ContactPage from "./components/Contact.vue";
import FooterBar from "./components/FooterBar.vue";
export default {
  name: "App",
  components: {
    HomeHero,
    CurriculumVita,
    NavBar,
    ContactPage,
    FooterBar,
  },
};
</script>

<style></style>
