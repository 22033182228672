<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative bg-white overflow-hidden">
    <div class="mx-auto">
      <div
        class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32"
      >
        <div>
          <!-- <div class="relative pt-6 px-4 sm:px-6 lg:px-8"></div> -->

          <main
            class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28"
          >
            <div class="sm:text-center lg:text-left">
              <div>
                <button @click="switchEnglish">
                  <div style="font-size: 50px">
                    <flag iso="us" />
                  </div>
                </button>
                <button class="pl-4" @click="switchGerman">
                  <div style="font-size: 50px">
                    <flag iso="de" />
                  </div>
                </button>
              </div>
              <h1
                class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl mb-3 pt-7"
              >
                <span
                  class="block xl:inline bullet"
                  v-if="$i18n.locale === 'de'"
                  >KR. Dr. Andreas<br />J. Ludwig</span
                >
                <span
                  class="block xl:inline bullet"
                  v-if="$i18n.locale === 'en'"
                  >Dr. Andreas<br />J. Ludwig</span
                >
              </h1>
              <div class="h-5"></div>
              <h2
                class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-3xl"
              >
                <span class="block xl:inline" v-if="$i18n.locale === 'de'"
                  >Über mich</span
                >
                <span class="block xl:inline" v-if="$i18n.locale !== 'de'"
                  >About me</span
                >
              </h2>
              <p
                class="mt-3 text-base sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
                v-html="$t('message')"
              ></p>
            </div>
          </main>
        </div>
      </div>
    </div>
    <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        class="h-56 w-full object-cover sm:h-96 md:h-96 lg:w-full lg:h-full"
        src="../assets/3K6A7596_1_k01.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { defineComponent } from "vue";

export default defineComponent({
  name: "HelloI18n",
  data() {
    return {
      showMenu: false,
      langs: ["en", "de"],
    };
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },
  methods: {
    switchEnglish() {
      this.$i18n.locale = "en";
    },
    switchGerman() {
      this.$i18n.locale = "de";
    },
  },
});
</script>
