<template>
  <nav
    class="relative flex flex-wrap items-center justify-between px-2 py-3 mb-3"
  >
    <div class="container px-4 flex flex-wrap items-center justify-between">
      <div
        class="w-full relative flex justify-between lg:w-auto px-4 lg:static lg:block lg:justify-start"
      >
        <img src="../assets/logo.png" calt="Flowbite Logo" class="h-16" />
        <button
          class="text- cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="toggleNavbar()"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <!-- <div
        v-bind:class="{ hidden: !showMenu, flex: showMenu }"
        class="lg:flex lg:flex-grow items-center"
      >
        <ul class="flex flex-col lg:flex-row list-none ml-auto">
          <li class="nav-item">
            <a
              class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75 bullet"
              href="#pablo"
            >
              <span class="ml-2">Home</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug bullet hover:opacity-75"
              href="#pablo"
            >
              <span class="ml-2">CURICULUM VITAE</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75 bullet"
              href="#contact"
            >
              <span class="ml-2">KONTAKT</span>
            </a>
          </li>
        </ul>
      </div> -->
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      showMenu: false,
      langs: ["en", "de"],
    };
  },
  methods: {
    toggleNavbar: function () {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
