<template>
  <div class="container text-base px-4 lg:px-8 pb-20">
    <div>
      <h1
        class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl pt-10 pb-10"
      >
        <span class="block xl:inline" v-if="$i18n.locale === 'de'"
          >Kontakt</span
        >
        <span class="block xl:inline" v-if="$i18n.locale !== 'de'"
          >Contact</span
        >
      </h1>
      <div>
        <section class="mb-6">
          <div
            class="lg:inline-block align-top lg:w-3/12 bullet font-bold"
            v-if="$i18n.locale === 'de'"
          >
            Adresse
          </div>
          <div
            class="lg:inline-block align-top lg:w-3/12 bullet font-bold"
            v-if="$i18n.locale !== 'de'"
          >
            Address
          </div>
          <div
            class="lg:inline-block align-top lg:w-8/12 w-full"
            v-if="$i18n.locale === 'de'"
          >
            AJL Corporate Advisors GmbH<br />Neue Welt Gasse 9<br />
            1130 Wien
          </div>
          <div
            class="lg:inline-block align-top lg:w-8/12 w-full"
            v-if="$i18n.locale !== 'de'"
          >
            AJL Corporate Advisors GmbH<br />Neue Welt Gasse 9<br />
            1130 Vienna
          </div>
        </section>
        <section class="mb-6">
          <div class="lg:inline-block lg:w-3/12 bullet font-bold">E-Mail</div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            <a href="mailto:andreas.ludwig@ajl-advisors.com"
              >andreas.ludwig@ajl-advisors.com</a
            >
          </div>
        </section>
        <section class="mb-6">
          <div class="lg:inline-block lg:w-3/12 bullet font-bold">Website</div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            <a href="mailto:andreas.ludwig@ajl-advisors.com"
              >ajl-advisors.com</a
            >
          </div>
        </section>
        <section class="mb-6">
          <div class="lg:inline-block lg:w-3/12 bullet font-bold">
            Mobil-Nr.
          </div>
          <div class="lg:inline-block lg:w-8/12 w-full">
            <a href="tel:+436648373880">+43 664 8373880</a>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContactPage",
};
</script>
