<template>
  <footer
    class="p-4 rounded-lg shadow md:flex md:items-center md:justify-between md:p-6 cool-bg text-white"
  >
    <span class="text-sm text-white sm:text-center" v-if="$i18n.locale === 'de'"
      >© {{ new Date().getFullYear() }}
      <a class="hover:underline text-white">AJL Corporate Advisors GmbH</a>.
      Alle Rechte vorbehalten.
    </span>
    <span class="text-sm text-white sm:text-center" v-if="$i18n.locale !== 'de'"
      >© {{ new Date().getFullYear() }}
      <a class="hover:underline text-white">AJL Corporate Advisors GmbH</a>. All
      Rights Reserved.
    </span>
    <ul
      class="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0"
    ></ul>
  </footer>
</template>
